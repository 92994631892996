import locale5f43b30e from '../../lang/en-GB.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","file":"en-GB.js","name":"English (GB)","icon":"🇬🇧","date":"enGB"},{"code":"en-IE","file":"en-GB.js","name":"English (IE)","icon":"🇮🇪"},{"code":"en-US","file":"en-US.js","name":"English (US)","icon":"🇺🇸"},{"code":"en-AU","file":"en-AU.js","name":"English (AU)","icon":"🇦🇺"},{"code":"es","file":"es.js","name":"Español","icon":"🇪🇸","date":"es"},{"code":"et","file":"et-EE.js","name":"Eesti keel (Eesti)","icon":"🇪🇪"},{"code":"nl","iso":"nl","file":"nl.js","name":"Nederlands","icon":"🇳🇱"},{"code":"fr-CA","iso":"fr-CA","file":"fr-CA.js","name":"Français (Canadian)","icon":"🇨🇦"},{"code":"it","iso":"it","file":"it.js","name":"Italiano","icon":"🇮🇹"},{"code":"sl","iso":"sl","file":"sl.js","name":"Slovenščina","icon":"🇸🇮"},{"code":"de","iso":"de","file":"de.js","name":"Deutsch","icon":"🇩🇪"},{"code":"ja","iso":"ja","file":"ja.js","intl":"ja.js","name":"日本語","icon":"🇯🇵","date":"ja"},{"code":"fa","iso":"fa","file":"fa.js","name":"فارسی","icon":"🇮🇷","dir":"rtl"},{"code":"pt","iso":"pt","file":"pt.js","name":"Português","icon":"🇵🇹"},{"code":"hr","iso":"hr","file":"hr.js","name":"Hrvatski","icon":"🇭🇷","date":"hr"},{"code":"hu","iso":"hu","file":"hu.js","name":"magyar","icon":"🇭🇺","date":"hu"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "/src/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","file":"en-GB.js","name":"English (GB)","icon":"🇬🇧","date":"enGB"},{"code":"en-IE","file":"en-GB.js","name":"English (IE)","icon":"🇮🇪"},{"code":"en-US","file":"en-US.js","name":"English (US)","icon":"🇺🇸"},{"code":"en-AU","file":"en-AU.js","name":"English (AU)","icon":"🇦🇺"},{"code":"es","file":"es.js","name":"Español","icon":"🇪🇸","date":"es"},{"code":"et","file":"et-EE.js","name":"Eesti keel (Eesti)","icon":"🇪🇪"},{"code":"nl","iso":"nl","file":"nl.js","name":"Nederlands","icon":"🇳🇱"},{"code":"fr-CA","iso":"fr-CA","file":"fr-CA.js","name":"Français (Canadian)","icon":"🇨🇦"},{"code":"it","iso":"it","file":"it.js","name":"Italiano","icon":"🇮🇹"},{"code":"sl","iso":"sl","file":"sl.js","name":"Slovenščina","icon":"🇸🇮"},{"code":"de","iso":"de","file":"de.js","name":"Deutsch","icon":"🇩🇪"},{"code":"ja","iso":"ja","file":"ja.js","intl":"ja.js","name":"日本語","icon":"🇯🇵","date":"ja"},{"code":"fa","iso":"fa","file":"fa.js","name":"فارسی","icon":"🇮🇷","dir":"rtl"},{"code":"pt","iso":"pt","file":"pt.js","name":"Português","icon":"🇵🇹"},{"code":"hr","iso":"hr","file":"hr.js","name":"Hrvatski","icon":"🇭🇷","date":"hr"},{"code":"hu","iso":"hu","file":"hu.js","name":"magyar","icon":"🇭🇺","date":"hu"}],
  localeCodes: ["en","en-IE","en-US","en-AU","es","et","nl","fr-CA","it","sl","de","ja","fa","pt","hr","hu"],
}

export const localeMessages = {
  'en-GB.js': () => Promise.resolve(locale5f43b30e),
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'en-AU.js': () => import('../../lang/en-AU.js' /* webpackChunkName: "lang-en-AU.js" */),
  'es.js': () => import('../../lang/es.js' /* webpackChunkName: "lang-es.js" */),
  'et-EE.js': () => import('../../lang/et-EE.js' /* webpackChunkName: "lang-et-EE.js" */),
  'nl.js': () => import('../../lang/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'fr-CA.js': () => import('../../lang/fr-CA.js' /* webpackChunkName: "lang-fr-CA.js" */),
  'it.js': () => import('../../lang/it.js' /* webpackChunkName: "lang-it.js" */),
  'sl.js': () => import('../../lang/sl.js' /* webpackChunkName: "lang-sl.js" */),
  'de.js': () => import('../../lang/de.js' /* webpackChunkName: "lang-de.js" */),
  'ja.js': () => import('../../lang/ja.js' /* webpackChunkName: "lang-ja.js" */),
  'fa.js': () => import('../../lang/fa.js' /* webpackChunkName: "lang-fa.js" */),
  'pt.js': () => import('../../lang/pt.js' /* webpackChunkName: "lang-pt.js" */),
  'hr.js': () => import('../../lang/hr.js' /* webpackChunkName: "lang-hr.js" */),
  'hu.js': () => import('../../lang/hu.js' /* webpackChunkName: "lang-hu.js" */),
}
