import Vue from 'vue'

export const state = () => ({
  moveReservation: null,
  rebookReservation: null,
  waitlist: null,
  serviceSelections: {},
  selectedSlot: null,
  selectedCustomer: null,
  selectedDate: null,
  mode: 'standard', // options standard, timetable, lastminute
  submission: null,
  voucher: null,
  pass: null,
  giftCard: null,
  guid: 'booking_' + (new Date()).getTime(),
  payment: {},
  filters: {}
})

export const mutations = {
  clear(state) {
    state.moveReservation = null
    state.rebookReservation = null
    state.waitlist = null
    state.serviceSelections = {}
    state.selectedSlot = null
    state.selectedCustomer = null
    state.selectedDate = null
    state.mode = 'standard'
    state.submission = null
    state.voucher = null
    state.pass = null
    state.giftCard = null
    state.guid = 'booking_' + (new Date()).getTime()
    state.payment = {}
    state.filters = {}
  },
  setMoveReservation(state, moveReservation) {
    state.moveReservation = moveReservation
  },
  setRebookReservation(state, rebookReservation) {
    state.rebookReservation = rebookReservation
  },
  setWaitlist(state, waitlist) {
    state.waitlist = waitlist
  },
  setServiceSelection(state, { service, selected, employee, order, disabled }) {
    let serviceSelection = state.serviceSelections[service.id]

    if (!serviceSelection) {
      serviceSelection = {}
      serviceSelection.service = service
      serviceSelection.selectedEmployee = null
      serviceSelection.selected = false
      serviceSelection.disabled = false
      serviceSelection.order = 0
    }

    if (selected !== undefined) serviceSelection.selected = selected
    if (employee !== undefined) serviceSelection.selectedEmployee = employee
    if (order !== undefined) serviceSelection.order = order
    if (disabled !== undefined) serviceSelection.disabled = disabled

    Vue.set(state.serviceSelections, service.id, serviceSelection)
  },
  clearServiceSelections(state) {
    state.serviceSelections = {}
  },
  setSelectedDate(state, date) {
    state.selectedDate = date
  },
  setSelectedSlot(state, { slot, mode }) {
    state.selectedSlot = slot
    state.mode = mode
  },
  setSelectedCustomer(state, customer) {
    state.selectedCustomer = customer
  },
  setMode(state, mode) {
    state.mode = mode
  },
  setSubmission(state, submission) {
    state.submission = submission
  },
  setVoucher(state, voucher) {
    state.voucher = voucher
  },
  setPass(state, pass) {
    state.pass = pass
  },
  setGiftCard(state, giftCard) {
    state.giftCard = giftCard
  },
  setPayment(state, { guid, payment }) {
    if (payment) {
      state.payment[guid] = payment
    } else {
      delete state.payment[guid]
    }
  },
  setFilter(state, { filter, value }) {
    if (filter) {
      state.filters[filter] = value
    } else {
      delete state.filters[filter]
    }
  },
  setFilters(state, filters) {
    state.filters = filters
  }
}

export const actions = {
  clear(context) {
    context.commit('clear')
  },
  setMoveReservation(context, { moveReservation, includeEmployees = false }) {
    context.commit('setMoveReservation', moveReservation)

    const year = moveReservation.date.toString().substring(0, 4)
    const month = moveReservation.date.toString().substring(4, 6)
    const day = moveReservation.date.toString().substring(6, 8)

    context.commit('setSelectedDate', new Date(year, month - 1, day))

    for (let i = 0; i < moveReservation.services.length; i++) {
      const reservationService = moveReservation.services[i]
      const employee = (includeEmployees) ? reservationService.employee : null

      context.dispatch('setServiceSelected', { service: reservationService.service, employee, selected: true, toBack: true })
    }

    if (moveReservation.voucher) {
      context.commit('setVoucher', moveReservation.voucher)
    }

    if (moveReservation.pass) {
      context.commit('setPass', moveReservation.pass)
    }
  },
  setRebookReservation(context, rebookReservation) {
    context.commit('setRebookReservation', rebookReservation)

    context.commit('setSelectedDate', new Date())

    for (let i = 0; i < rebookReservation.services.length; i++) {
      const reservationService = rebookReservation.services[i]

      context.dispatch('setServiceSelected', { service: reservationService.service, selected: true, toBack: true })
    }
  },
  setWaitlist(context, { waitlist, moveReservation = null }) {
    context.commit('setWaitlist', waitlist)
    if (moveReservation) {
      context.commit('setMoveReservation', moveReservation)
    }
  },
  clearServiceSelections(context) {
    context.commit('clearServiceSelections')
  },
  setSelectedDate(context, date) {
    context.commit('setSelectedDate', date)
  },
  setSelectedSlot(context, { slot, mode }) {
    context.commit('setSelectedSlot', { slot, mode })
  },
  setSelectedCustomer(context, customer) {
    context.commit('setSelectedCustomer', customer)
  },
  setMode(context, mode) {
    context.commit('setMode', mode)
  },
  setSubmission(context, submission) {
    context.commit('setSubmission', submission)
  },
  setVoucher(context, voucher) {
    context.commit('setVoucher', voucher)
  },
  setPass(context, pass) {
    context.commit('setPass', pass)
  },
  setGiftCard(context, giftCard) {
    context.commit('setGiftCard', giftCard)
  },
  setPayment(context, { guid, payment }) {
    context.commit('setPayment', { guid, payment })
  },
  setFilter(context, { filter, value }) {
    context.commit('setFilter', { filter, value })
  },
  setFilters(context, filters) {
    context.commit('setFilters', filters)
  },
  setServiceSelected(context, { service, selected, employee, toBack, reorderAndDisable }) {
    if (reorderAndDisable === undefined) reorderAndDisable = true
    if (toBack === undefined) {
      const selection = context.state.serviceSelections[service.id]
      if (!selection || !selection.selected) {
        toBack = true
      }
    }

    const services = context.rootState.location.services
    // get full version of service if available
    if (services) {
      for (let i = 0; i < services.categories.length; i++) {
        const category = services.categories[i]
        for (let j = 0; j < category.services.length; j++) {
          if (category.services[j].id == service.id) {
            service = category.services[j]
          }
        }
      }
    }

    context.commit('setServiceSelection', { service, selected, employee })

    if (toBack) {
      context.commit('setServiceSelection', { service, order: (context.getters.maxOrder + 1) })
    }

    if (services) {
      for (let i = 0; i < services.categories.length; i++) {
        const category = services.categories[i]
        for (let j = 0; j < category.services.length; j++) {
          if (category.services[j].id !== service.id) {
            context.commit('setServiceSelection', { service: category.services[j], disabled: false })
          }
        }
      }
    }

    if (reorderAndDisable) {
      context.dispatch('reorder')
      context.dispatch('disableServices')
    }
  },
  reorder(context) {
    const serviceOrders = []

    for (const id in context.state.serviceSelections) {
      if (context.state.serviceSelections[id]) {
        const selection = context.state.serviceSelections[id]

        if (selection.selected) {
          const serviceInfo = {}
          serviceInfo.service = selection.service
          serviceInfo.order = selection.order

          serviceOrders.push(serviceInfo)
        }
      }
    }

    serviceOrders.sort(function (a, b) {
      return (a.order - b.order)
    })

    for (let i = 0; i < serviceOrders.length; i++) {
      context.commit('setServiceSelection', { service: serviceOrders[i].service, order: (i + 1) })
    }
  },
  disableServices(context) {
    let hasGroupSelected = false
    let hasNonGroupSelected = false

    const services = context.rootGetters['location/allServices']

    for (let i = 0; i < services.length; ++i) {
      const selection = context.state.serviceSelections[services[i].id]
      if (selection && selection.selected) {
        if (services[i].customerType === 1) {
          hasGroupSelected = true
        } else {
          hasNonGroupSelected = true
        }
      }
    }

    for (let i = 0; i < services.length; ++i) {
      const selection = context.state.serviceSelections[services[i].id]
      if (!selection || !selection.selected) {
        if (hasGroupSelected) {
          context.commit('setServiceSelection', { service: services[i], disabled: true })
        } else if (hasNonGroupSelected && services[i].customerType === 1) {
          context.commit('setServiceSelection', { service: services[i], disabled: true })
        } else {
          context.commit('setServiceSelection', { service: services[i], disabled: false })
        }
      }
    }
  },
  async setFromUrlQuery(context, query) {
    // context.dispatch('booking/clear', null, { root: true })

    const currentQuery = context.getters.urlQuery

    let servicesPromise = null
    let slotPromise = null
    let rebookPromise = null
    let movePromise = null
    let voucherPromise = null
    let passPromise = null

    if (((query.services && currentQuery.services !== query.services) || (query.slot && currentQuery.slot !== query.slot)) && !context.rootGetters['location/services']) {
      servicesPromise = context.dispatch('location/getServices', null, { root: true })
    }

    if (!query.slot) {
      if (slotPromise) context.commit('setSelectedSlot', {})
    } else if (query.slot && currentQuery.slot !== query.slot) {
      slotPromise = this.$api.booking.slot(query.slot)
    }

    if (query.rebook && currentQuery.rebook !== query.rebook) {
      const rebookJson = Buffer.from(query.rebook, 'base64').toString()
      const rebookObject = JSON.parse(rebookJson)

      rebookPromise = this.$api.customer.reservation(rebookObject.id, rebookObject.date, { id: rebookObject.customer }, rebookObject.signature)
    }
    if (query.move && currentQuery.move !== query.move) {
      const moveJson = Buffer.from(query.move, 'base64').toString()
      const moveObject = JSON.parse(moveJson)

      movePromise = this.$api.customer.reservation(moveObject.id, moveObject.date, { id: moveObject.customer }, moveObject.signature)
    }

    if (query.voucher && currentQuery.voucher !== query.voucher) {
      voucherPromise = this.$api.booking.voucher(query.voucher).catch((error) => error)
    }

    if (query.pass && currentQuery.pass !== query.pass) {
      const passJson = Buffer.from(query.pass, 'base64').toString()
      const passObject = JSON.parse(passJson)

      passPromise = this.$api.booking.customerPass(passObject.id, passObject.signature)
    }

    await Promise.all([servicesPromise, slotPromise, rebookPromise, movePromise, voucherPromise, passPromise]).then((values) => {
      if (slotPromise) context.commit('setSelectedSlot', { slot: values[1].slot })
      if (rebookPromise) context.commit('setRebookReservation', values[2].reservation)
      if (movePromise) context.commit('setMoveReservation', values[3].reservation)
      if (voucherPromise) context.commit('setVoucher', values[4]?.voucher)
      if (passPromise) context.commit('setPass', values[5].customerPass)

      if (values[1] && values[1].slot) {
        for (let i = 0; i < values[1].slot.services.length; i++) {
          const slotService = values[1].slot.services[i]

          context.commit('setServiceSelection', { service: slotService.service, employee: slotService.employee, selected: true, order: (i + 1) })
        }

        context.dispatch('booking/disableServices', null, { root: true })
      }
    })

    if (!query.services && !query.slot) {
      context.dispatch('booking/clearServiceSelections', null, { root: true })
    } else if (query.services && currentQuery.services !== query.services) {
      const json = Buffer.from(query.services, 'base64').toString()
      const serviceData = JSON.parse(json)

      const services = context.rootGetters['location/allServices']

      for (let i = 0; i < serviceData.length; i++) {
        const serviceInfo = serviceData[i]

        const service = services.find((service) => {
          return (service.id == serviceInfo.s)
        })

        if (service) {
          let employeeService = null
          if (serviceInfo.e) {
            employeeService = service.employeeServices.find((employeeService) => {
              return (employeeService.employee.id == serviceInfo.e)
            })
          }

          let employee = null
          if (employeeService && employeeService.employee) employee = employeeService.employee

          context.commit('setServiceSelection', { service, employee, selected: true, order: (i + 1) })
        }
      }

      context.dispatch('booking/disableServices', null, { root: true })
    }

    context.commit('setMode', (query.mode) ? query.mode : 'standard')
    context.commit('setWaitlist', (query.waitlist) ? query.waitlist : null)
    context.commit('setGiftCard', (query.giftcard) ? query.giftcard : null)

    if (!query.customer) {
      context.commit('setSelectedCustomer', null)
    } else if (query.customer && currentQuery.customer !== query.customer) {
      const json = Buffer.from(query.customer, 'base64').toString()
      const customer = JSON.parse(json)

      context.commit('setSelectedCustomer', customer)
    }

    if (!query.date) {
      context.commit('setSelectedDate', null)
    } else if (query.date && currentQuery.date !== query.date) {
      context.commit('setSelectedDate', new Date(parseInt(query.date)))
    }

    context.commit('setFilters', {})

    for (const [key, value] of Object.entries(query)) {
      if (key.startsWith('f.')) {
        const filter = key.substring(2)
        context.commit('setFilter', { filter, value })
      }
    }
  }
}

export const getters = {
  guid(state) {
    return state.guid
  },
  moveReservation(state) {
    return state.moveReservation
  },
  rebookReservation(state) {
    return state.rebookReservation
  },
  waitlist(state) {
    return state.waitlist
  },
  selectedDate(state) {
    return state.selectedDate
  },
  selectedSlot(state) {
    return state.selectedSlot
  },
  selectedCustomer(state) {
    return state.selectedCustomer
  },
  serviceSelections(state) {
    return state.serviceSelections
  },
  submission(state) {
    return state.submission
  },
  voucher(state) {
    return state.voucher
  },
  pass(state) {
    return state.pass
  },
  giftCard(state) {
    return state.giftCard
  },
  payment(state) {
    return state.payment
  },
  filters(state) {
    return state.filters
  },
  selectedServices(state) {
    const selectedServices = []

    const serviceSelections = state.serviceSelections

    for (const id in serviceSelections) {
      if (serviceSelections[id]) {
        const selection = serviceSelections[id]

        if (selection.selected) {
          selectedServices.push(selection)
        }
      }
    }
    selectedServices.sort(function (a, b) {
      return (a.order - b.order)
    })

    return selectedServices
  },
  maxOrder(state) {
    let maxOrder = 0

    const serviceSelections = state.serviceSelections

    for (const id in serviceSelections) {
      if (serviceSelections[id]) {
        const selection = serviceSelections[id]

        if (selection.selected) {
          if (selection.order > maxOrder) {
            maxOrder = selection.order
          }
        }
      }
    }

    return maxOrder
  },
  urlQuery(state) {
    const query = {}
    if (state.serviceSelections) {
      const selectedServices = []
      const serviceSelections = state.serviceSelections

      for (const id in serviceSelections) {
        if (serviceSelections[id]) {
          const selection = serviceSelections[id]

          if (selection.selected) {
            selectedServices.push(selection)
          }
        }
      }

      selectedServices.sort(function (a, b) {
        return (a.order - b.order)
      })

      const serviceData = selectedServices.map((serviceSelection) => {
        const serviceInfo = { s: serviceSelection.service.id }
        if (serviceSelection.selectedEmployee) serviceInfo.e = serviceSelection.selectedEmployee.id
        return serviceInfo
      })

      if (serviceData.length > 0) {
        query.services = Buffer.from(JSON.stringify(serviceData)).toString('base64')
      }
    }
    if (state.selectedSlot) {
      query.slot = state.selectedSlot.data
    }
    if (state.selectedDate) {
      query.date = state.selectedDate.getTime()
    }
    if (state.selectedCustomer) {
      query.customer = Buffer.from(JSON.stringify(state.selectedCustomer)).toString('base64')
    }
    if (state.submission) {
      query.submission = Buffer.from(JSON.stringify(state.submission)).toString('base64')
    }
    if (state.rebookReservation) {
      const rebookObject = {
        id: state.rebookReservation.id,
        date: state.rebookReservation.date,
        customer: state.rebookReservation.customer.id,
        signature: state.rebookReservation.signature
      }
      query.rebook = Buffer.from(JSON.stringify(rebookObject)).toString('base64')
    }
    if (state.moveReservation) {
      const moveObject = {
        id: state.moveReservation.id,
        date: state.moveReservation.date,
        customer: state.moveReservation.customer.id,
        signature: state.moveReservation.signature
      }
      query.move = Buffer.from(JSON.stringify(moveObject)).toString('base64')
    }

    if (state.voucher) {
      query.voucher = state.voucher.code
    }

    if (state.pass) {
      const passObject = {
        id: state.pass.id,
        signature: state.pass.signature
      }
      query.pass = Buffer.from(JSON.stringify(passObject)).toString('base64')
    }

    if (state.giftCard) {
      query.giftcard = state.giftCard
    }

    if (state.mode !== 'standard') {
      query.mode = state.mode
    }

    if (state.waitlist) {
      query.waitlist = state.waitlist
    }

    if (state.filters) {
      for (const [key, value] of Object.entries(state.filters)) {
        query['f.' + key] = value
      }
    }

    return query
  }
}
