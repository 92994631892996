<template>
  <nuxt-link
    v-if="path && (!isTab ||isEnabled)"
    :to="path"
    class="block py-2 px-3 rounded font-medium hover:bg-action transition-all flex items-center space-x-3"
    :class="classes"
  >
    <icon v-if="icon" :name="item.icon || icons[item.id]" />
    <div>
      <span class="block">{{ name }}</span>
      <span v-if="info && information" class="text-xs opacity-70">{{ information }}</span>
    </div>
  </nuxt-link>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    Icon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    icon: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      paths: {
        book: "/book-now",
        timetable: "/timetable",
        lastMinute: "/last-minute",
        giftCard: "/gift",
        pass: "/pass",
        gallery: "/gallery",
        review: "/reviews",
        user: "/customer"
      },
      icons: {
        book: "clock",
        timetable: "calendar-days",
        lastMinute: "bell-alert",
        giftCard: "gift",
        pass: "ticket",
        gallery: "photo",
        review: "star",
        user: "user"
      }
    }
  },
  computed: {
    name () {
      return this.item?.name || this.$site.tabs.getName(this.item?.id, this.item?.id)
    },
    path () {
      if (this.item?.type === 'custom' && this.item?.slug) {
        return `/page/${this.item?.slug}`
      }

      return this.paths[this.item?.id]
    },
    classes () {
      const classes = []

      if (this.isActive) {
        classes.push('text-action bg-action')
      } else {
        classes.push('hover:text-action text-[var(--bookapp-hero-text-color)]')

        if (this.icon) {
          classes.push('text-secondary')
        } else {
          classes.push('text-[var(--bookapp-hero-text-color)]')
        }
      }

      return classes.join(' ')
    },
    information () {
      return this.item.information
    },
    isHome () {
      return this.$route.path === '/'
    },
    isActive () {
      return this.$route.path.startsWith(this.path)
    },
    isEnabled () {
      return !!this.$site.tabs[this.item?.id]
    },
    isTab () {
      return this.item?.type === 'tab'
    }
  }
}
</script>
